import React, {useState, useEffect} from 'react'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {
    Dropdown,
    ConfigProvider,
    Space,
    Radio,
    DatePicker,
    InputNumber,
    message,
    Typography,
    Col,
    Row,
    Form,
    Input,
    Spin,
    Flex,
    Button
} from 'antd'
import {GlobalOutlined} from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import zhTW from 'antd/locale/zh_TW'
import enUS from 'antd/locale/en_US'
import zhCN from 'antd/locale/zh_CN'
import langZhtw from './locales/zh-tw'
import * as ToolRequest from './tools/ToolRequest'
import ReCAPTCHA from "react-google-recaptcha";
import {I18n, setLocale} from 'react-redux-i18n'
import Import from './components/Import'

dayjs.locale('zh-tw')
const {Title, Text} = Typography
const {TextArea} = Input

const formLayout = {
    labelCol: {
        md: {span: 5},
        sm: {span: 24},
    },
    wrapperCol: {
        md: {span: 16},
    },
}

const formTailLayout = {
    wrapperCol: {
        md: {offset: 5, span: 16},
    },
}

function App() {
    const recaptchaRef = React.createRef();
    const location = useLocation()
    const i18n = useSelector(state => state.i18n)
    const lang = i18n.locale
    const [form] = Form.useForm()
    const {state} = location
    const refundRecordId = state && state.refundRecordId
    const {shopNo} = useParams()
    const [isVisibleOther, setIsVisibleOther] = useState(false)
    const [isVisiblePhoto, setIsVisiblePhoto] = useState(false)
    const [isVisibleAmount, setIsVisibleAmount] = useState(false)
    const [isVisibleBank, setIsVisibleBank] = useState(false)
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage()
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refundPayments, setRefundPayments] = useState(null)
    const navigate = useNavigate()
    const [address, setAddress] = useState(null)

    let locale = zhTW
    if (i18n) {
        switch (i18n.locale) {
            case 'en':
                locale = enUS
                break
            case 'zh-cn':
                locale = zhCN
                break
        }
    }

    const refundPaymentsOnchange = ()=>{
        const payment = form.getFieldValue('payment')
        if(payment){
            if (payment === 'octopus' || payment === 'crypto') {
                setRefundPayments([<Radio value="coupon" key="coupon">{I18n.t('coupon')}</Radio>,<Radio value="bank_transfer" key="bank_transfer">{I18n.t('bank_transfer')}</Radio>])
            } else {
                setRefundPayments([<Radio value="coupon" key="coupon">{I18n.t('coupon')}</Radio>,<Radio value="e_wallet" key="e_wallet">{I18n.t('e_wallet')}</Radio>])
            }
        }
    }
    const onValuesChange = (changedValues, allValues) => {
        if (changedValues.paymentRecord) {
            if (changedValues.paymentRecord === 'photo') {
                setIsVisibleAmount(false)
                setIsVisiblePhoto(true)
            } else if ('amount') {
                setIsVisiblePhoto(false)
                setIsVisibleAmount(true)
            }
        }
        if (changedValues.reason) {
            if (changedValues.reason === 'other') {
                setIsVisibleOther(true)
            } else {
                setIsVisibleOther(false)
            }
        }
        if (changedValues.payment) {
            refundPaymentsOnchange()
        }
        if (changedValues.refundMethod) {
            if (changedValues.refundMethod === 'bank_transfer') {
                setIsVisibleBank(true)
            } else {
                setIsVisibleBank(false)
            }
        }
    }
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                if (refundRecordId) {
                    let obj = {}
                    const res = await ToolRequest.request('GET', `/record/${refundRecordId}`)
                    if(res.state !== 'PENDING'){
                        await Promise.reject(new Error('refund_approved'))
                    }
                    dispatch(setLocale(res.locale));
                    let reason = 'other'
                    let reasonKeys = ['without_delivery', 'delivery_with_problem']
                    for (let reasonKey of reasonKeys) {
                        if (langZhtw[reasonKey] === res.userDescription) {
                            reason = reasonKey
                        }
                    }
                    if (reason === 'other') {
                        obj.otherReason = res.userDescription
                    }
                    obj.reason = reason
                    obj.payment = res.userPaidType
                    obj.amount = res.userRefundAmount
                    obj.refundMethod = res.userRefundType
                    obj.contactNumber = res.contactNumber
                    obj.whatsApp = res.userWhatsApp
                    if (res.userPaidAt) {
                        obj.paidAt = dayjs(res.userPaidAt)
                    }
                    if (res.urls.length) {
                        obj.paymentRecord = 'photo'
                        setFileList(res.urls.map(item => {
                            return {
                                url: item.url,
                                assetId: item.assetId
                            }
                        }))
                    } else {
                        obj.paymentRecord = 'amount'
                    }
                    if (res.userRefundInformation) {
                        const info = JSON.parse(res.userRefundInformation)
                        obj.payee_name = info.payee_name
                        obj.bank_account_number = info.bank_account_number
                    }
                    form.setFieldsValue(obj)
                    onValuesChange(obj)
                }
                const kioskRes = await ToolRequest.request('GET', `/kiosk`,{shopNo,locale:lang})
                setAddress(kioskRes.address)
                setLoading(false)
            } catch (e) {
                console.dir(e)
                if (e?.response?.data) {
                    switch (e.response.data) {
                        case 'NOT_FOUND': {
                            message.error(e.response.data)
                            break
                        }
                        default:
                            return message.error(I18n.t('errorMessageUnknownError'))
                    }
                }else {
                    console.dir(e.message)
                    return message.error(I18n.t(e.message))
                }
            }
        })()
    }, [lang])
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const onFinish = async (values) => {
        setLoading(true)
        let assetIds = []
        console.dir(fileList)
        if (values.paymentRecord === 'photo') {
            for (let list of fileList) {
                if (list.assetId) {
                    assetIds.push(list.assetId)
                } else {
                    assetIds.push(list.response.assetId)
                }
            }
        }
        let reason = ``
        if (values.reason === 'other') {
            reason = values.otherReason
        } else {
            reason = langZhtw[values.reason]
        }
        let userRefundInformation = null
        if (values.refundMethod === 'bank_transfer') {
            userRefundInformation = JSON.stringify({
                payee_name: values.payee_name,
                bank_account_number: values.bank_account_number
            })
        }
        try {
            let params = {
                userDescription: reason,
                payment: values.payment,
                userRefundAmount: values.amount,
                userPaidAt: values.paidAt,
                userRefundType: values.refundMethod,
                contactNumber: values.contactNumber,
                userWhatsApp: values.whatsApp,
                userRefundInformation,
                shopNo,
                locale: lang,
                assetIds: assetIds.length ? assetIds : null
            }
            console.dir(params)
            if (refundRecordId) {
                await ToolRequest.request('PUT', `/record/${refundRecordId}`, params)
                navigate(`/success`, {state: {refundRecordId, shopNo}, replace: true})
            } else {
                const resultRes = await ToolRequest.request('POST', `/record`, params)
                navigate(`/success`, {state: {refundRecordId: resultRes.id, shopNo}, replace: true})
            }
        } catch (e) {
            message.error(I18n.t('errorMessageUnknownError'))
        } finally {
            setLoading(false)
        }
    }
    return (
        <ConfigProvider locale={locale}>
            <Spin spinning={loading}>
                <Row style={{padding: '15px'}}>
                    <Col span={18}>
                        <Typography>
                            <Title level={5}>{I18n.t('site_number')}: {shopNo}</Title>
                            <Title level={5}>{I18n.t('site_address')}: {address}</Title>
                        </Typography>
                    </Col>
                    <Col span={6}>
                        <Flex justify="center" align="center" style={{height: '100%'}}>
                            <Dropdown
                                menu={
                                    {
                                        items: [
                                            {
                                                label: '繁',
                                                key: 'zh-tw',
                                            },
                                            {
                                                label: '简',
                                                key: 'zh-cn',
                                            },
                                            {
                                                label: 'En',
                                                key: 'en',
                                            },
                                        ],
                                        onClick: ({key}) => {
                                            dispatch(setLocale(key))
                                            refundPaymentsOnchange()
                                        }
                                    }
                                }
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {I18n.t('switch_language')}
                                        <GlobalOutlined/>
                                    </Space>
                                </a>
                            </Dropdown>
                        </Flex>
                    </Col>
                </Row>
                <Row style={{padding: '15px'}}>
                    <Col span={24}>
                        <Text type="danger">{I18n.t('notice')}</Text>
                    </Col>
                </Row>
                <Row style={{padding: '15px'}}>
                    <Col span={24}>
                        <Form
                            {...formLayout}
                            form={form}
                            name="basic"
                            initialValues={{
                                paidAt: dayjs()
                            }}
                            style={{fontSize: 35}}
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                        >
                            <Form.Item
                                label={I18n.t('reason_for_refund')}
                                name="reason"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value="without_delivery">{I18n.t('without_delivery')}</Radio>
                                        <Radio value="delivery_with_problem">{I18n.t('delivery_with_problem')}</Radio>
                                        <Radio value="other">{I18n.t('other_reason')}</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {isVisibleOther === true ? (<Form.Item label={I18n.t('other_reason')}
                                                                   name="otherReason"
                            >
                                <TextArea placeholder={I18n.t('other_reason')} rows={4} maxLength={150}/>
                            </Form.Item>) : null}
                            <Form.Item
                                label={I18n.t('payment')}
                                name="payment"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value="octopus">{I18n.t('octopus')}</Radio>
                                        <Radio value="aliPay/weChatPay">{I18n.t('ali_weChat')}</Radio>
                                        <Radio value="payme">PayMe</Radio>
                                        <Radio value="crypto">{I18n.t('crypto')}</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label={I18n.t('payment_records')}
                                name="paymentRecord"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value="photo">{I18n.t('screen_capture')}</Radio>
                                        <Radio value="amount">{I18n.t('amount_pay_time')}</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {isVisiblePhoto === true ? (<Form.Item
                                label={I18n.t('picture')}
                                name="photo"
                                rules={[
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!fileList.length) {
                                                return Promise.reject(new Error(I18n.t('at_least_one_picture')));
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Import
                                    accept=".jpg, .jpeg, .png"
                                    action={`${window.getConfig().apiHost}/screen-shot`}
                                    listType="picture"
                                    maxCount={3}
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    buttonText={I18n.t('click_to_upload')}
                                />
                            </Form.Item>) : null}
                            {isVisibleAmount === true ? (<Form.Item
                                label={I18n.t('payment_amount')}
                                name="amount"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{width: 200}}
                                    min={0}
                                    step={0.01}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>) : null}
                            {isVisibleAmount === true ? (<Form.Item
                                label={I18n.t('payment_time')}
                                name="paidAt"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                            </Form.Item>) : null}
                            <Form.Item
                                label={I18n.t('refund_method')}
                                name="refundMethod"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        {refundPayments}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {isVisibleBank === true ? (
                                <Form.Item
                                    label={I18n.t('payee_name')}
                                    name="payee_name"
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={I18n.t('payee_name')}
                                    />
                                </Form.Item>
                            ) : null}
                            {isVisibleBank === true ? (
                                <Form.Item
                                    label={I18n.t('bank_account_number')}
                                    name="bank_account_number"
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                >
                                    <Input maxLength={30}
                                           placeholder={I18n.t('bank_account_number')}
                                    />
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label={I18n.t('contact_number')}
                                name="contactNumber"
                                dependencies={['whatsApp']}
                                rules={[
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value && !getFieldValue('whatsApp')) {
                                                return Promise.reject(new Error(I18n.t('phone_whatsApp_required_tip')));
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input maxLength={30} placeholder={I18n.t('contact_number')}/>
                            </Form.Item>
                            <Form.Item
                                label={I18n.t('WhatsApp')}
                                name="whatsApp"
                                dependencies={['contactNumber']}
                                rules={[
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value && !getFieldValue('contactNumber')) {
                                                return Promise.reject(new Error(I18n.t('phone_whatsApp_required_tip')));
                                            } else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input maxLength={50} placeholder={I18n.t('WhatsApp')}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span></span>}
                                colon={false}
                                name="recaptcha"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={window.getConfig().recaptchaSiteKey}
                                    onChange={onChange}
                                />
                            </Form.Item>
                            <Form.Item {...formTailLayout}>
                                <Button type="primary" style={{backgroundColor: '#389e0d'}} htmlType="submit" block>
                                    {I18n.t('submit')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row></Spin>
            {contextHolder}
        </ConfigProvider>
    )
}

export default App
